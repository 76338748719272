




























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Stepper extends Vue {
  @Prop({
    type: Number,
    default: 1,
  })
  private step!: number;
}
