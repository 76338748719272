



























































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { FormInterface } from "@/types/component.type";
import QuotationModule from "@/store/modules/quotation-module";
import { QuotationInterface } from "@/types/quotation.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { ProjectModule } from "@/store/modules/project-module";
import CloseButton from "@/components/buttons/CloseButton.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";

@Component({
  components: {
    SaveButton,
    CloseButton,
  },
})
export default class ProjectQuotationForm extends Vue implements FormInterface {
  public $refs!: {
    form: HTMLFormElement;
  };

  private valid: boolean = true;

  @Prop({
    default: true,
    type: Boolean,
  })
  private readonly editMode!: boolean;

  @Prop({
    type: Function,
    default() {
      return;
    },
  })
  private afterSubmit!: () => void;

  @Prop({
    type: Function,
  })
  private simpleClose!: () => void;

  @Prop({
    type: [Number, String],
    default: null,
  })
  private readonly projectId!: number | null | string;

  @Prop({
    type: Object,
    default: null,
  })
  private quotationDetail!: QuotationInterface;

  private quotationNameRule = [(v: any) => !!v || "Quotation name is required"];

  private defaultFakeQuotation: QuotationInterface = {
    project: null,
    number: "",
    serviceNumber: "",
    fake: false,
  };

  public clearForm(): void {
    this.quotationDetail = {
      project: 0,
      number: "",
      serviceNumber: "",
      remark: "",
      fake: false,
    };
  }

  private async saveQuotation() {
    if (this.$refs.form.validate()) {
      if (this.editMode) {
        await this.updateQuotationDetail();
      } else {
        await this.createNewQuotation();
      }
      // fetch project
      await ProjectModule.getProjectById(this.projectId as number);
      this.afterSubmit();
    } else {
      await SnackbarModule.setSnack({
        color: "error",
        message: "Please fill in all the fields",
      });
    }
  }

  private async createNewQuotation() {
    try {
      let data;
      if (this.quotationDetail.fake) {
        data = {
          ...this.defaultFakeQuotation,
          fake: this.quotationDetail.fake,
          number: this.quotationDetail.number,
          project: this.quotationDetail.project,
        };
      } else {
        data = { ...this.quotationDetail };
      }
      await QuotationModule.addQuotation(data as QuotationInterface);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Create New quotation successfully!",
    });
  }

  private async updateQuotationDetail() {
    try {
      let data;
      if (this.quotationDetail.fake) {
        data = {
          ...this.defaultFakeQuotation,
          id: this.quotationDetail.id,
          fake: this.quotationDetail.fake,
          number: this.quotationDetail.number,
          project: this.quotationDetail.project,
        };
      } else {
        data = { ...this.quotationDetail };
      }
      await QuotationModule.updateQuotation(data as QuotationInterface);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Update quotation successfully!",
    });
  }

  @Watch("quotationDetail.fake")
  private onQuotationDetailFakeChange(val: boolean, oldVal: boolean) {
    if (val && !oldVal) {
      this.quotationDetail.number = "Fake Default Quotation";
    } else if (!val && oldVal) {
      this.quotationDetail.number = "";
      this.$refs.form.reset();
    }
  }
}
