

































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { PositionInterface, ProjectPositionInterface } from "@/types/project.type";
import { FormInterface } from "@/types/component.type";
import { DurationTypeInterface } from "@/types/durationType.type";
import { DurationTypeModule } from "@/store/modules/duration-module";
import ProjectForm from "@/components/project/ProjectForm.vue";
import PositionSelector from "@/components/project/PositionSelector.vue";
import { getDurationTypeById, secondToDurationUnit } from "@/utils/unitConverstion";
import { RoleMixin } from "@/mixins/role-mixin";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import SaveButton from "@/components/buttons/SaveButton.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import Header from "@/components/Header.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  components: {
    ProjectForm,
    ConfirmationDialog,
    PositionSelector,
    SaveButton,
    CloseButton,
    Header,
  },
})
export default class PositionCard extends Mixins(RoleMixin) implements FormInterface {
  get durationTypes(): DurationTypeInterface[] {
    return DurationTypeModule.durationTypes;
  }

  get isConvertible(): boolean {
    return this.isActive && this.editMode;
  }

  get durationTypeByDay(): DurationTypeInterface | undefined {
    return DurationTypeModule.durationTypes.find((type) => type.seconds === 28800);
  }

  public positionErrorMessage = "";
  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }
  public position = "";
  public $refs!: {
    form: HTMLFormElement;
  };

  @Prop({
    default: false,
    type: Boolean,
  })
  private readonly editMode!: boolean;

  @Prop({
    default: false,
    type: Boolean,
  })
  private readonly isActive!: boolean;

  // project id
  @Prop({
    required: true,
  })
  private readonly id!: number;

  @Prop({
    type: [Number],
    default: 0,
  })
  private readonly index!: number;

  private projectPosition: ProjectPositionInterface = {
    positionCharge: 0,
    durationLimit: 0,
    project: this.id,
    noDurationLimit: false,
    durationType: this.durationTypeByDay?.id,
    quotation: 0,
    position: {} as PositionInterface,
    key: this.index,
  };

  @Prop({
    type: Function,
  })
  private afterSubmit!: () => void;

  @Prop({
    type: Function,
  })
  private remove!: (index: number) => void;

  @Prop({
    type: Function,
  })
  private update!: (index: number, value: ProjectPositionInterface) => void;

  @Prop({
    type: [Boolean],
    default: null,
  })
  private readonly viewMode!: boolean;

  private positionNameRule = [(v: any) => !!v || "Position name is required"];

  private positionChargeRule = [(v: PositionInterface) => !!v || "Position Charge is required"];

  private zeroAndNoneEmptyPricePerUnitRule = [(v: string) => !!String(v) || "Price Per Unit is required"];

  private valid: boolean = true;

  public clearForm(): void {
    this.projectPosition = {
      position: {} as PositionInterface,
      positionCharge: 0,
      noDurationLimit: false,
      durationLimit: 0,
      project: 0,
      durationType: this.durationTypeByDay?.id,
      quotation: 0,
    };
  }

  public changeSecondToTypeUnit() {
    const durationTypeId = this.projectPosition.durationType;
    const durationType = getDurationTypeById(this.durationTypes, durationTypeId);
    const durationLimitInSecond = this.projectPosition.durationLimit;
    const durationInUnit = secondToDurationUnit(durationLimitInSecond, durationType);
    this.projectPosition.durationLimit = !durationInUnit ? durationLimitInSecond : durationInUnit;
  }

  @Watch("isConvertible")
  private watchIsConvertible(val: boolean) {
    if (val) {
      this.changeSecondToTypeUnit();
    }
  }

  @Watch("isActive")
  private watchIsActive(val: boolean) {
    if (!val) {
      this.positionErrorMessage = "";
    }
  }

  private async created() {
    await DurationTypeModule.getDurationTypes();
  }

  private mounted() {
    this.update(this.index, this.projectPosition);
    if (this.isConvertible) {
      this.changeSecondToTypeUnit();
    }
  }

  private onSelectPosition(data: PositionInterface) {
    this.projectPosition.position = data;
  }

  private zeroAndNoneEmptyDurationLimitIfHasDurationLimit(input: any) {
    if (this.projectPosition.noDurationLimit) {
      return true;
    }
    return !!String(input) || "Duration limit is required";
  }
}
