





















































import { Component, Prop } from "vue-property-decorator";
import { PositionInterface, ProjectInterface, ProjectPositionInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import { DurationTypeInterface } from "@/types/durationType.type";
import { DurationTypeModule } from "@/store/modules/duration-module";
import { durationUnitToSecond, getDurationTypeById } from "@/utils/unitConverstion";
import ProjectQuotationForm from "@/components/project/ProjectQuotationForm.vue";
import { QuotationInterface } from "@/types/quotation.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import QuotationModule from "@/store/modules/quotation-module";
import ProjectBreadcrumb from "@/components/project/ProjectBreadcrumb.vue";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import SaveButton from "@/components/buttons/SaveButton.vue";
import CreateButton from "@/components/buttons/CreateButton.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";
import PositionCard from "@/components/project/PositionCard.vue";
import Stepper from "@/components/Stepper.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";
import { cloneDeep } from "lodash";

@Component({
  components: {
    ProjectQuotationForm,
    ProjectBreadcrumb,
    SaveButton,
    CreateButton,
    CloseButton,
    PositionCard,
    Stepper,
    ConfirmationDialog,
  },
})
export default class QuotationList extends DurationHelperMixins {
  get project(): ProjectInterface {
    return ProjectModule.project;
  }

  public $refs!: {
    quotationForm: HTMLFormElement;
    deleteDialog: HTMLFormElement;
  };

  private positionForm: object = {};

  // project id
  @Prop({
    required: true,
  })
  private readonly id!: number;

  private editModeStatus: boolean = false;

  private step: number = 1;

  private quotationForm: QuotationInterface = {
    project: this.id,
    number: "",
    serviceNumber: "",
    positions: [],
    fake: false,
  };

  private key: number = 0;

  private quotationFormDefault: QuotationInterface = {
    project: this.id,
    number: "",
    serviceNumber: "",
    positions: [],
    fake: false,
  };

  private positionList: ProjectPositionInterface[] = [];

  private defaultFakeQuotation: QuotationInterface = {
    project: null,
    number: "",
    serviceNumber: "",
    fake: false,
  };

  private addPositionToList(): void {
    this.key++;
    const projectPositionDefault: ProjectPositionInterface = {
      positionCharge: 0,
      durationLimit: 0,
      project: this.id,
      noDurationLimit: false,
      durationType: this.durationTypeByDay?.id,
      quotation: 0,
      position: {} as PositionInterface,
      key: this.key,
    };
    this.positionList.push(projectPositionDefault);
  }

  private removePositionFromList(key: number): void {
    const index = this.positionList.findIndex((each) => each.key === key);
    this.positionList.splice(index, 1);
  }

  private async created() {
    await ProjectModule.getProjectById(this.id);
    this.quotationForm = Object.assign({}, this.quotationFormDefault);
  }

  private updatePosition(key: number, value: ProjectPositionInterface) {
    const index = this.positionList.findIndex((each) => each.key === key);
    this.positionList[index] = value;
  }

  private async createNewQuotation() {
    try {
      let data;
      if (this.quotationForm.fake) {
        data = {
          ...this.defaultFakeQuotation,
          fake: this.quotationForm.fake,
          number: this.quotationForm.number,
          project: this.quotationForm.project,
          positions: this.quotationForm.positions,
        };
      } else {
        data = { ...this.quotationForm };
      }
      await QuotationModule.addQuotation(data as QuotationInterface);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Create New quotation successfully!",
    });
  }

  private back() {
    if (this.step === 1) {
      this.$router.push({
        name: "Project Detail",
        params: { id: this.$route.params.id },
      });
    } else {
      this.step = 1;
    }
  }

  get durationTypeByDay(): DurationTypeInterface | undefined {
    return DurationTypeModule.durationTypes.find((type) => type.seconds === 28800);
  }

  get durationTypes(): DurationTypeInterface[] {
    return DurationTypeModule.durationTypes;
  }

  private getDurationInSecond(durationLimitValue: number | null, durationTypeId?: number | null) {
    const durationType = getDurationTypeById(this.durationTypes, durationTypeId);
    return durationUnitToSecond(durationLimitValue, durationType);
  }

  private async nextStep() {
    if (this.step === 1) {
      this.step = 2;
    } else {
      this.quotationForm.positions = cloneDeep(this.positionList);

      // format duration
      for (const position of this.quotationForm.positions) {
        position.durationLimit = this.getDurationInSecond(position.durationLimit, this.durationTypeByDay?.id);
      }

      await this.createNewQuotation();

      await this.$router.push({
        name: "Project Detail",
        params: { id: this.$route.params.id },
      });
    }
  }
}
